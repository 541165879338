import React from 'react';

import { UqArticleQuote, UqCustomerStory, UqTypography, UqContainerV2, UqLink, UqLayoutV2, UqAppBarV2, UqFooterV2, UqNavbarV2, UqPage, UqCustomerProfile, UqRequestDemoBanner } from '@uq-components';
import Logo from '@uq-assets/logos/cornershop.svg';

export default function CornershopPage() {
  return (
    <UqPage
      config={{
        seo: {
          description: 'Learn how Cornershop used actionable insights from unitQ to deliver enhanced product quality',
          src: '/images/v2/customers/cornershop/cornershop-hero.png',
          title: 'Cornershop by Uber Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header backgroundSrc="/images/v2/customers/cornershop/cornershop-hero.png">
            <UqTypography as="h1">
              {'Cornershop + unitQ: <br /> With Actionable Insights from unitQ, Cornershop by Uber Delivers Enhanced Product Quality'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Grocery-Product Delivery</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">Santiago, Chile</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">800+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                No Single Source of Truth to Quantify, Qualify User Feedback
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Results">
                <ul>
                  <li>Fewer customer support tickets</li>
                  <li>Increased app store star ratings</li>
                  <li>Prioritized product roadmap</li>
                  <li>Enhanced customer and shopper experience</li>
                </ul>
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>
              <p>
                <UqLink
                  className="article-link"
                  to="https://www.cornershopapp.com/"
                  external
                  hideArrow
                >
                  Cornershop
                </UqLink>
                {' '}
                by Uber is an on-demand grocery app that frees people from the hassles of in-store shopping.
                It&apos;s an easy way to order online and get fresh food and other products delivered on time the
                same day in Latin America, Canada and the United States.
                Simply tap to order and a shopper will carefully select, pack, and deliver
                your purchases at a time that fits any schedule.
              </p>

              <UqTypography as="h4">
                The Problem: !-No Single Source of Truth to Quantify, Qualify User Feedback-!
              </UqTypography>

              <p>
                Cornershop by Uber, like many popular companies, is the subject of an immense amount of customer feedback —
                be it on the Google Play Store, App Store, Twitter, Zendesk, social media and you name it.
                And that made work life frustrating for Alejandra Santana, Head of Product Inbound for Cornershop by Uber.
                As the company was scaling, it was becoming more difficult to quantify and take action on what users were saying in English,
                French, German, Spanish, Portuguese and other languages.
              </p>
              <p>
                Cornershop by Uber required a complete accounting of all their product quality feedback,
                with alerting, so they could fix issues faster,
                address problems and enhance their services overall to increase customer satisfaction.
                The company was spending too much time on “long manual processing” of support tickets.
                This meant it often took too long to find out about shopping
                and delivery issues such as missing or late deliveries, Santana says.
              </p>

              <UqTypography as="h4">
                The Solution: !-unitQ Surfaces Customer Feedback From Social Media, App Stores,
                Internal Customer Ticketing Systems and Other Sources-!
              </UqTypography>

              <p>
                Cornershop by Uber did not have a detailed accounting of product quality issues identified by their customers,
                which also include the company&apos;s shoppers who use the company&apos;s apps to manage deliveries. unitQ Monitor,
                unitQ&apos;s AI-enabled product quality platform, was able to capture valuable feedback from their shoppers,
                including their need for more paper bags because there was so much demand for their services.
              </p>
              <p>
                The company chose unitQ to empower them with the tools to turn user feedback and shopper feedback,
                in various languages — and from dozens of external and internal sources — into actionable insights to fix issues
                and roll out new features in a planned, coordinated manner.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Alejandra Santana',
                  role: 'Head of Product Inbound for Cornershop by Uber',
                }}
              >
                With the real-time product quality data streaming in from unitQ, it&apos;s like our customers are now on our support team.
                unitQ has enabled us to mine user feedback, the best resource for identifying quality issues, bugs, and errors.
              </UqArticleQuote>

              <p>
                Some of Santana&apos;s favorite features of the unitQ Monitor include alerts and saved searches.
                Some of the alerts concern problems with account activation, missing or wrong deliveries and
                even alerts about shoppers reporting GPS issues. Some of the dozens of saved searches concern account issues,
                giving Santana and team a clear view about them from any time period, and on any application.
              </p>

              <UqTypography as="h4">
                The results: !-Cornershop by Uber is Delivering Better Product Quality-!
              </UqTypography>

              <p>
                Before the positive results began flowing in, Cornershop by Uber first needed to be onboarded to unitQ.
                Santana says then setup experience provided by unitQ consultants was “very helpful and supportive.”
              </p>

              <UqArticleQuote
                author={{
                  name: 'Alejandra Santana',
                  role: 'Head of Product Inbound for Cornershop by Uber',
                }}
              >
                unitQ was willing to change and adapt some features to our way of working.
                Not many companies are willing or able to customize the user experience like unitQ has done for Cornershop by Uber.
              </UqArticleQuote>

              <span>
                There&apos;s plenty of positive outcomes to note. For Cornershop by Uber, the benefits of harnessing unitQ include:
              </span>

              <ul>
                <li>Fewer customer support tickets.</li>
                <li>Increased app store star ratings.</li>
                <li>Prioritized product roadmap.</li>
                <li>Enhanced customer and shopper experience.</li>
                <li>
                  Developed a tech support team with Cornershop by Uber agents capable of handling,
                  investigating, prioritizing and assigning incidents.
                </li>
                <li>
                  Adopted new abilities to find unknown issues in order to resolve them as soon as possible.
                </li>
              </ul>

              <UqArticleQuote
                author={{
                  name: 'Alejandra Santana',
                  role: 'Head of Product Inbound for Cornershop by Uber',
                }}
              >
                Enhancing the customer experience by detecting, investigating, prioritizing and fixing issues
                identified by users is one of the main priorities of Cornershop by Uber.
                Without unitQ, I don&apos;t think we could deliver on that priority of product quality.
              </UqArticleQuote>
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />

      </UqLayoutV2>
    </UqPage>
  );
}
